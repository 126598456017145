import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { isEmpty } from '../../constants/utility';
import Loader from '../Loader';

class LocationReportView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locationid: "",
            location_name: "",
            image_date_time: "",
            additional_information: '',
            email_address: '',
            location_problem: '',
            location_image_url: '',
            username: "",
            uid: "",
            ssrs_userid: "",
            status: '',
            errormessage: "",
            error: null,
            fetching: false,
            btnName: "Add"
        };
    }

    componentDidMount() {

        if (!isEmpty(this.props.location.state.location_report)) {
            //console.log(this.props.location.state.product);
            let record = this.props.location.state.location_report;
            this.setState({
                uid: record.uid,
                ssrs_userid: record.ssrs_userid,
                locationid: record.locationid,
                location_name: record.location_name,
                image_date_time: record.image_date_time,
                email_address: record.email_address,
                username: record.username,
                additional_information: record.additional_information,
                location_problem: record.location_problem,
                location_image_url: record.location_image_url,
            });

        } else {
            //console.log("add record");
        }
    }

    render() {
        const { uid, ssrs_userid, locationid, location_name, image_date_time, username, email_address, location_image_url, location_problem, additional_information  } = this.state;
        return (
            <section className="staticBdrContainer">
                <div className="loginFormContainer">
                    <form>
                        <div className="lgRow">
                            <label htmlFor="labelUID" className="labelUID">User ID </label>
                            <input
                                id="userid"
                                name="userid"
                                value={uid}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="labelUID" className="labelUID">SSRS UserID </label>
                            <input
                                id="locationid"
                                name="locationid"
                                value={ssrs_userid}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="labelUID" className="labelUID">Location ID </label>
                            <input
                                id="locationid"
                                name="locationid"
                                value={locationid}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="labelUID" className="labelUID">LOCATION NAME </label>
                            <input
                                id="location_name"
                                name="location_name"
                                value={location_name}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="labelUsername" className="labelUsername">USERNAME </label>
                            <input
                                id="username"
                                name="username"
                                value={username}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="labelEmail" className="labelEmail">EMAIL </label>
                            <input
                                id="emailaddress"
                                name="emailaddress"
                                value={email_address}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                        <div className="lgRow padTp1_5 url">
                            <label htmlFor="labelLocation" className="labelLocation">LOCATION IMAGE</label>
                            <a href={location_image_url} className="button button-link" target="_blank">View</a>
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="labelUID" className="labelUID">IMAGE SUBMISSION DATE TIME </label>
                            <input
                                id="image_date_time"
                                name="image_date_time"
                                value={image_date_time}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="labelLocation" className="labelLocation">LOCATION PROBLEM</label>
                            <input
                                id="location_problem"
                                name="location_problem"
                                value={location_problem}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="labelAge" className="labelAge">ADDITIONAL INFORMATION</label>
                            <input
                                id="additional_information"
                                name="additional_information"
                                value={additional_information}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                    </form>
                </div>

                <Loader isVisible={this.state.fetching} />
            </section>
        )
    }

}

export default withFirebase(LocationReportView);