import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { getDatabase, onValue, remove } from "firebase/database";
import * as ROUTES from '../../constants/routes';
import Loader from '../Loader';
import { isStringEmpty } from '../../constants/utility';
import moment from 'moment';

class Campaigns extends Component {
    constructor(props) {
        super(props);
        this.db = getDatabase();
        this.ref = this.props.firebase.campaigns();
        this.unsubscribe = null;
        this.state = {
            campaigns: [],
            fetching: false,
        }
    }
    onCollectionUpdate = (querySnapshot) => {
        const campaigns = [];

        const prod = querySnapshot.val();
        if (prod) {
            const campaignslst = Object.keys(prod).map(key => ({
                ...prod[key],
                id: key,
            }));
            // console.log("news list:- ", newslst.reverse());
            this.setState({
                campaigns: campaignslst.reverse(),
            });
            console.log("campaigns: "+campaigns);
        }else{
            this.setState({
                campaigns: [],
            });
        }

        this.setState({
            fetching: false,
        });

    }

    componentDidMount() {
        this.setState({ fetching: true });
        this.unsubscribe = onValue(this.ref, this.onCollectionUpdate);
    }

    deleteCampaign(data) {
        remove(this.props.firebase.campaignEditAdd(data.id)).then(() => {
            onValue(this.ref, this.onCollectionUpdate);
        });
    }

    renderCampaigns = () => {


        if (this.state.campaigns.length > 0) {
            let campaigns = this.state.campaigns.map(data => {
                return (
                    <li className="slpkgItm" key={"TS" + data.id}>

                        <div className="sr">{data.title}</div>
                        <div className="url">
                            <a href={data.imageUrl} className="button button-link" target="_blank">View</a>
                        </div>

                        <div className="status" style={{ textTransform: "capitalize" }} >
                            {!isStringEmpty(data.status) ? data.status : 'N/A'}
                        </div>
                        <div className="date">
                            {!isStringEmpty(data.publishDate) ? moment(data.publishDate, "DD-MM-YYYY").format("DD/MM/YYYY"): 'N/A'}
                        </div>
                        <div className="userAction">

                            <Link to={{ pathname: ROUTES.CAMPAIGNADDEDIT, state: { campaign: data } }} className="button button-primary button-small" title="Edit">
                                <span className="skpkgviewall">Edit</span>
                            </Link>
                            <button className="button button-primary button-small" onClick={(e) => {
                                this.deleteCampaign(data)
                            }}>Delete</button>
                        </div>
                    </li>
                )
            });
            return campaigns;
        } else {
            return null;
        }
    }
    render() {
        return (
            <main className="container flexbox listPage">
                <section className="staticBdrContainer" style={{ display: "block" }}>
                    <div className="srAddToCart">
                        <div className="addToCartBtnWpr">
                            <Link to={{ pathname: ROUTES.CAMPAIGNADDEDIT, state: { news: {} } }} className="button button-primary button-small" title="Edit">
                                <span className="skpkgviewall">Add</span>
                            </Link>
                        </div>
                    </div>
                    <div className="listWrapper">
                        <div className="listContent">
                            <div className="listWpr">
                                <div className="listCnt">
                                    <ul className="slPkgUk">
                                        <li className="slpkgHdr" >
                                            <div className="sr" >Title</div>
                                            <div className="url" key='1'>Image</div>
                                            <div className="status" key='2'>Status</div>
                                            <div className="date" key='3'>Publish Date</div>
                                            <div className="action" key='4'></div>
                                        </li>
                                        {this.renderCampaigns()}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Loader isVisible={this.state.fetching} />
                <div>

                </div>
            </main>
        );
    }
}

export default withFirebase(Campaigns);