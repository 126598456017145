import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { isEmpty } from '../../constants/utility';
import Loader from '../Loader';

class UserView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uid: "",
            age: '',
            email: '',
            location: '',
            username: "",
            sas_via_email: "",
            status: '',
            errormessage: "",
            error: null,
            fetching: false,
            btnName: "Add"
        };
    }

    componentDidMount() {

        if (!isEmpty(this.props.location.state.user)) {
            //console.log(this.props.location.state.product);
            let records = this.props.location.state.user;
            this.setState({
                uid: records.id,
                email: records.email,
                username: records.username,
                sas_via_email: records.sas_via_email,
                age: records.age,
                location: records.location,
            });

        } else {
            //console.log("add record");
        }
    }

    render() {
        const { uid, age, email, location, username, sas_via_email } = this.state;
        return (
            <section className="staticBdrContainer">
                <div className="loginFormContainer">
                    <form>
                        <div className="lgRow">
                            <label htmlFor="labelUID" className="labelUID">UID </label>
                            <input
                                id="uid"
                                name="uid"
                                value={uid}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="labelUsername" className="labelUsername">USERNAME </label>
                            <input
                                id="username"
                                name="username"
                                value={username}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="labelEmail" className="labelEmail">EMAIL </label>
                            <input
                                id="email"
                                name="email"
                                value={email}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="labelAge" className="labelAge">AGE </label>
                            <input
                                id="age"
                                name="age"
                                value={age}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="labelLocation" className="labelLocation">LOCATION </label>
                            <input
                                id="location"
                                name="location"
                                value={location}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                        <div className="lgRow padTp1_5">
                            <label htmlFor="labelUsername" className="labelUsername">SAS via Email </label>
                            <input
                                id="username"
                                name="username"
                                value={sas_via_email}
                                type="text"
                                readOnly={ true }
                                className="InputText"
                                maxLength="100"
                                required
                            />
                        </div>
                    </form>
                </div>

                <Loader isVisible={this.state.fetching} />
            </section>
        )
    }

}

export default withFirebase(UserView);