import React from 'react';
import { Link } from 'react-router-dom';
import topapplogo from '../../assets/image/topapplogo.png';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';


import { AuthUserContext } from '../Session';

const Navigation = () => (
  
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth /> : null
      }
    </AuthUserContext.Consumer>
  </div>
);

function NavigationAuth(){
  return(<div className="container">
     <header className="fixed-top">
      <nav className="navbar">
        <div className="logo-wrapper">
          <Link to={{ pathname: 'home', state: { slctDate: "" } }} className="navbar-brand" title="Homepage">
            <img src={topapplogo} alt="Safer Seas & Rivers Service" style={{width:"30px"}} />
          </Link>
          
        </div>
        <div className="top-nav">
            <Link to={{ pathname: ROUTES.USERS, state: { slctDate: "" } }} className="navbar-brand basket-button button-link inline-block fontBold" title="Users">
                Users
            </Link>
            <Link to={{ pathname: ROUTES.LOCATIONREPORTS, state: { slctDate: "" } }} className="navbar-brand basket-button button-link inline-block fontBold" title="Location Reports">
                Location Reports
            </Link>
            <Link to={{ pathname: ROUTES.CAMPAIGNS, state: { slctDate: "" } }} className="navbar-brand basket-button button-link inline-block fontBold" title="Campaigns">
                Campaigns
            </Link>
            <div className="lidivion" ></div>
            <SignOutButton />
        </div>
      </nav>
    </header> 
    
  </div>);
}
export default Navigation;

