import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from '../Navigation';
import SignInPage from '../SignIn';
import Users from '../Users';
import Campaigns from '../Campaigns';
import CampaignAddEdit from '../CampaignAddEdit';
import HomePage from '../Home';
import UserView from '../UserView';
import LocationReports from '../LocationReports';
import LocationReportView from '../LocationReportView';
// import PushPage from '../PushPage';
import '../../assets/css/login.css';
import '../../assets/css/App.css';
import '../../assets/css/button.css';
import '../../assets/css/navigation.css';
import '../../assets/css/listpage.css';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

const App = () => (
    <Router>
        <div className="contentWrapper">
            <Navigation />
            {/* <Route exact path={ROUTES.LANDING} component={LandingPage} /> */}
            {/* <Route  path={ROUTES.SIGN_UP} component={SignUpPage} /> */}
            <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route exact path={ROUTES.LANDING} component={SignInPage} />
            <Route  path={ROUTES.HOME} component={HomePage} />
            <Route  path={ROUTES.USERS} component={Users} />
            <Route  path={ROUTES.USERVIEW} component={UserView} />
            <Route  path={ROUTES.LOCATIONREPORTS} component={LocationReports} />
            <Route  path={ROUTES.LOCATIONREPORTVIEW} component={LocationReportView} />
            <Route  path={ROUTES.CAMPAIGNS} component={Campaigns} />
            <Route path={ROUTES.CAMPAIGNADDEDIT} component={CampaignAddEdit}/>

        </div>
    </Router>
);

export default withAuthentication(App);
