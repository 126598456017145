import React, { Component } from 'react';
import loader from '../../assets/image/ajax-loader.gif';

class Loader extends Component {

    constructor(props) {
        super(props);
    }


    /**
     * @function is invoked immediately after a component is mounted. Initialization that requires DOM nodes should go here.
     * If you need to load data from a remote endpoint, this is a good place to instantiate the network request.
     */
    componentDidMount() {
        //every time the NavigationBar is loaded we check if the user api_token and token is available
        //it the api_token and token is available we do hit getUserDetails Webservice

    }

    /**
     * @function This is invoked when an update is caused by changes to props or state.
     * These methods are called when a component is being re-rendered
     * @param nextProps
     */
    componentWillReceiveProps(nextProps) {

    }


    render() {
        return (
            <div className="loader" style={{ display: this.props.isVisible ? "block" : "none" }}>
                <img alt="Loader" src={loader} className="loader-gif" />
            </div>
        );
    }
}

export default Loader
