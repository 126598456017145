import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { getDatabase, onValue, remove } from "firebase/database";
import * as ROUTES from '../../constants/routes';
import Loader from '../Loader';

class LocationReports extends Component {
    constructor(props) {
        super(props);
        this.db = getDatabase();
        this.ref = this.props.firebase.locationReports();
        this.unsubscribe = null;
        this.state = {
            location_reports: [],
            fetching: false,
        }
    }
    onCollectionUpdate = (querySnapshot) => {
        const location_reports = [];

        const prod = querySnapshot.val();
        if (prod) {
            const location_reportslst = Object.keys(prod).map(key => ({
                ...prod[key],
                id: key,
            }));
            // console.log("news list:- ", newslst.reverse());
            this.setState({
                location_reports: location_reportslst.reverse(),
            });
        }

        this.setState({
            fetching: false,
        });

    }

    componentDidMount() {
        this.setState({ fetching: true });
        this.unsubscribe = onValue(this.ref, this.onCollectionUpdate);
    }

    deleteLocationReport(data) {
        remove(this.props.firebase.locationReportView(data.id)).then(() => {
            onValue(this.ref, this.onCollectionUpdate);
        });
    }

    renderUsers = () => {


        if (this.state.location_reports.length > 0) {
            let location_reports = this.state.location_reports.map(data => {
                console.log(data);
                return (
                    <li className="slpkgItm" key={"TS" + data.id}>

                        <div className="username">{data.ssrs_userid}</div>
                        <div className="username">{data.locationid}</div>
                        <div className="email">{data.email_address}</div>
                        <div className="username">{data.location_problem}</div>
                        <div className="userAction">

                            <Link to={{ pathname: ROUTES.LOCATIONREPORTVIEW, state: { location_report: data } }} className="button button-primary button-small" title="Edit">
                                <span className="skpkgviewall">View</span>
                            </Link>
                            <button className="button button-primary button-small" onClick={(e) => {
                                this.deleteLocationReport(data)
                            }}>Delete</button>
                        </div>
                    </li>
                )
            });
            return location_reports;
        } else {
            return null;
        }
    }
    render() {
        return (
            <main className="container flexbox listPage">
                <section className="staticBdrContainer" style={{ display: "block" }}>
                    <div className="srAddToCart">
                        <div className="addToCartBtnWpr">
                            {/*<Link to={{ pathname: ROUTES.NEWSADDEDIT, state: { users: {} } }} className="button button-primary button-small" title="Edit">*/}
                                {/*/!* <img src={require('../../assets/image/edit.svg')} alt="Add" className="awardIcon" /> *!/*/}
                                {/*<span className="skpkgviewall">Add</span>*/}
                            {/*</Link>*/}
                        </div>
                    </div>
                    <div className="listWrapper">
                        <div className="listContent">
                            <div className="listWpr">
                                <div className="listCnt">
                                    <ul className="slPkgUk">
                                        <li className="slpkgHdr" >
                                            <div className="username" >SSRS User ID</div>
                                            <div className="username" key='2'>Location ID</div>
                                            <div className="email" key='3'>Email</div>
                                            <div className="username" key='4'>Location Problem</div>
                                            <div className="action" key='5'></div>
                                        </li>
                                        {this.renderUsers()}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Loader isVisible={this.state.fetching} />
                <div>

                </div>
            </main>
        );
    }
}

export default withFirebase(LocationReports);