import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref } from "firebase/database";
import { getStorage } from 'firebase/storage';
import { LOGOUTURL } from "../../constants/routes";
//Dev Config
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Firebase Keys
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

class Firebase {
  constructor() {
    // Initialize Firebase Authentication and get a reference to the service
    this.auth = getAuth(app);
    this.db = getDatabase(app);
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
      signInWithEmailAndPassword(this.auth, email, password);

  doSignOut = () => {this.auth.signOut(); window.location.href=LOGOUTURL};
  users =()=> ref(this.db, 'users');//app.firestore(); //this.db..ref("products");
  userView =id=>ref(this.db, `users/${id}`);
  //locationReport =(id, locationId)=>ref(this.db, `users/${id}/location_report/${locationId}`);
  locationReports =()=>ref(this.db, `location_reports`);
  locationReportView =id=>ref(this.db, `location_reports/${id}`);
  campaigns =()=> ref(this.db, 'campaigns');//app.firestore(); //this.db..ref("products");
  campaignEditAdd =id=>ref(this.db, `campaigns/${id}`);
  storage = ()=> getStorage(app);
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  pushData =()=>ref(this.db, 'pushData');
  

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);
}

export default Firebase;
