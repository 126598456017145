import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { getDatabase, onValue, remove } from "firebase/database";
import * as ROUTES from '../../constants/routes';
import Loader from '../Loader';
import { CSVLink } from "react-csv";

class Users extends Component {
    constructor(props) {
        super(props);
        this.db = getDatabase();
        this.ref = this.props.firebase.users();
        this.unsubscribe = null;
        this.state = {
            users: [],
            fetching: false,
        }
    }
    onCollectionUpdate = (querySnapshot) => {
        const users = [];

        const prod = querySnapshot.val();
        if (prod) {
            const userslst = Object.keys(prod).map(key => ({
                ...prod[key],
                id: key,
            }));
            // console.log("news list:- ", newslst.reverse());
            this.setState({
                users: userslst.reverse(),
            });
        }

        this.setState({
            fetching: false,
        });

    }

    componentDidMount() {
        this.setState({ fetching: true });
        this.unsubscribe = onValue(this.ref, this.onCollectionUpdate);
    }

    deleteUser(data) {
        remove(this.props.firebase.userView(data.id)).then(() => {
            onValue(this.ref, this.onCollectionUpdate);
        });
    }

    renderUsers = () => {


        if (this.state.users.length > 0) {
            let users = this.state.users.map(data => {
                console.log(data);
                return (
                    <li className="slpkgItm" key={"TS" + data.id}>

                        <div className="uid">{data.id}</div>
                        <div className="username">{data.ssrs_userid}</div>
                        <div className="email">{data.email}</div>
                        <div className="username">{data.name}</div>
                        <div className="username">{data.sas_via_email}</div>
                        <div className="userAction">

                            <Link to={{ pathname: ROUTES.USERVIEW, state: { user: data } }} className="button button-primary button-small" title="Edit">
                                <span className="skpkgviewall">View</span>
                            </Link>
                            {/*<button className="button button-primary button-small" onClick={(e) => {*/}
                                {/*this.deleteUser(data)*/}
                            {/*}}>Delete</button>*/}
                        </div>
                    </li>
                )
            });
            return users;
        } else {
            return null;
        }
    }
    render() {
        const headers = [
            { label: "Name", key: "name" },
            { label: "Email", key: "email" },
            { label: "Age", key: "age" },
            { label: "SAS via Email", key: "sas_via_email" }
        ];

        const csvFilename = "userData"+moment().format("DD-MM-YYYY")+".csv";

        return (
            <main className="container flexbox listPage">
                <section className="staticBdrContainer" style={{ display: "block" }}>
                    <div className="srAddToCart">
                        <div className="addToCartBtnWpr">
                            {/*<Link to={{ pathname: ROUTES.NEWSADDEDIT, state: { users: {} } }} className="button button-primary button-small" title="Edit">*/}
                                {/*/!* <img src={require('../../assets/image/edit.svg')} alt="Add" className="awardIcon" /> *!/*/}
                                {/*<span className="skpkgviewall">Add</span>*/}
                            {/*</Link>*/}
                            <CSVLink data={this.state.users}
                                     headers={headers}
                                     filename={csvFilename}
                                     className="navbar-brand basket-button button-link inline-block fontBold"
                                     title={"Download CSV"} >
                                Download CSV
                            </CSVLink>
                        </div>
                    </div>
                    <div className="listWrapper">
                        <div className="listContent">
                            <div className="listWpr">
                                <div className="listCnt">
                                    <ul className="slPkgUk">
                                        <li className="slpkgHdr" >
                                            <div className="uid" >Uuid</div>
                                            <div className="username" key='2'>SSRS UserID</div>
                                            <div className="email" key='3'>Email</div>
                                            <div className="username" key='4'>Name</div>
                                            <div className="username" key='5'>SAS via Email</div>
                                            <div className="action" key='6'></div>
                                        </li>
                                        {this.renderUsers()}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Loader isVisible={this.state.fetching} />
                <div>

                </div>
            </main>
        );
    }
}

export default withFirebase(Users);