import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import { set, push } from "firebase/database";
import {
  ref,
  updateMetadata,
  uploadBytesResumable,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { isEmpty, isValidURL } from "../../constants/utility";
import Loader from "../Loader";
import CKEditor from "react-ckeditor-component";
import moment from "moment";

class CampaignAddEdit extends Component {
  constructor(props) {
    super(props);
    this.ref = this.props.firebase.campaigns();
    this.uploadFile = this.props.firebase.storage();
    this.unsubscribe = null;
    this.state = {
      id: "",
      title: "",
      imageUrl: "",
      callToActionURL: "",
      description: "",
      status: "draft",
      url: "",
      publishDate: "",
      errormessage: "",
      error: null,
      fetching: false,
      isDraftDisable: false,
      isActiveDisable: false,
      isInactiveDisable: false,
      btnName: "Add",
      statusArry: [
        { id: "active", name: "Active" },
        { id: "inactive", name: "Inactive" },
        { id: "draft", name: "Draft" },
      ],
      date: "",
    };
  }

  getInputValues = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeHandler = (event) => {
    //console.log(event.target.files[0]);
    this.setState({ [event.target.name]: event.target.files[0] });
    this.setState({ url: "" });
  };

  uploadImage = () => {
    const {
      id,
      title,
      imageUrl,
      description,
      status,
      url,
      publishDate,
      callToActionURL,
      date,
    } = this.state;

    // var date = new Date().getDate(); //Current Date
    // var month = new Date().getMonth() + 1; //Current Month
    // var year = new Date().getFullYear(); //Current Year
    // var hrs = new Date().getHours();
    // var mins = new Date().getMinutes();
    // var secs = new Date().getSeconds();
    var dateTime = moment().format("DD/MM/YYYY hh:mm:ss"); //date + "/" + month + "/" + year + " " + hrs + ":" + mins + ":" + secs;
    // console.log();

    if (url === "") {
      // console.log("add or edit image uploaded");
      var name = imageUrl.name.split(".");
      var imageType = "image/" + name[1];
      var metadata = {
        contentType: imageType,
      };

      // console.log(metadata);
      const storageRef = ref(this.uploadFile, `images/${imageUrl.name}`);
      const uploadImg = uploadBytesResumable(storageRef, imageUrl, metadata);
      //     .then((metadata) => {
      //         console.log(metadata);
      //     }).catch((error) => {
      //     console.log(error);
      // });

      uploadImg.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          this.setState({ fetching: false });
          // console.log(error);
        },
        () => {
          getDownloadURL(uploadImg.snapshot.ref).then((url) => {
            //ref(this.uploadFile, "images").child(imageUrl.name).getDownloadURL().then(url => {
            const campaign = {
              title: title,
              imageUrl: url !== "" ? url : imageUrl,
              description: description,
              callToActionURL: callToActionURL,
              status: status,
              date: date,
              publishDate:
                publishDate === "" && status === "active"
                  ? dateTime
                  : publishDate,
            };
            if (id === "") {
              //  console.log("add record");
              push(this.ref, campaign)
                .then((data) => {
                  // console.log(data);
                  this.setState({ fetching: false });
                  this.props.history.goBack();
                })
                .catch((error) => {
                  this.setState({ fetching: false });
                  this.setState({ error });
                });
            } else {
              //  console.log("edit record");
              set(this.props.firebase.campaignEditAdd(id), campaign)
                .then((data) => {
                  // console.log(data);
                  this.setState({ fetching: false });
                  this.props.history.goBack();
                })
                .catch((error) => {
                  this.setState({ fetching: false });
                  this.setState({ error });
                });
            }
          });
        }
      );
    } else {
      //console.log("edit record");
      const product = {
        title: title,
        imageUrl: url !== "" ? url : imageUrl,
        description: description,
        callToActionURL: callToActionURL,
        status: status,
        date: date,
        publishDate:
          publishDate === "" && status === "active" ? dateTime : publishDate,
      };
      set(this.props.firebase.campaignEditAdd(id), product)
        .then((data) => {
          //console.log(data);
          this.setState({ fetching: false });
          this.props.history.goBack();
        })
        .catch((error) => {
          this.setState({ fetching: false });
          this.setState({ error });
        });
    }
  };

  onSubmit = (event) => {
    event.preventDefault();
    console.log(this.state);
    const { title, imageUrl, description, url, callToActionURL, date } =
      this.state;
    let image = imageUrl === "" ? url : imageUrl;
    // console.log("imageUrl", image);
    //console.log("url", url);
    this.setState({ fetching: true });
    if (
      title.trim() === "" ||
      image === "" ||
      description.trim() === "" ||
      callToActionURL.trim() === "" ||
      date.trim() === ""
    ) {
      this.setState({ errormessage: "All fields are mandatory." });
      this.setState({ fetching: false });
      return;
    } else if (isValidURL(callToActionURL.trim())) {
      this.setState({ errormessage: "Add proper URL for Call to action." });
      this.setState({ fetching: false });
    } else {
      this.setState({ errormessage: "" });
      this.uploadImage();
    }
  };
  componentDidMount() {
    console.log(this.props.firebase.campaigns());
    if (!isEmpty(this.props.location.state.campaign)) {
      //console.log(this.props.location.state.product);
      let records = this.props.location.state.campaign;
      this.setState({
        id: records.id,
        title: records.title,
        description: records.description,
        url: records.imageUrl,
        callToActionURL: records.callToActionURL,
        status: records.status,
        isDraftDisable:
          records.status === "active" || records.status === "inactive"
            ? true
            : false,
        isActiveDisable: false,
        isInactiveDisable: false,
        btnName: "Update",
        date: records.date,
      });
    } else {
      //console.log("add record");
    }
  }
  selectedStatus = (e) => {
    // console.log(e.target.value);
    this.setState({ status: e.target.value });
  };
  renderStatus() {
    let status = this.state.statusArry.map((item, i) => {
      if (this.state.status === item.id) {
        //  console.log("inside if condition");
        return (
          <option key={i} value={item.id} selected>
            {item.name}
          </option>
        );
      } else {
        //console.log("inside else condition");
        return (
          <option
            key={i}
            value={item.id}
            disabled={
              item.id === "active"
                ? this.state.isActiveDisable
                : item.id === "inactive"
                ? this.state.isInactiveDisable
                : this.state.isDraftDisable
            }
          >
            {item.name}
          </option>
        );
      }
    });
    return status;
  }

  afterPaste = (evt) => {
    //console.log("afterPaste event called with event info: ", evt.editor.getData());
    var campaignContent = evt.editor.getData();
    // console.log("data onchnage",newContent);
    this.setState({ description: campaignContent });
  };
  onChange = (evt) => {
    // console.log("onChange fired with event info: ", evt);
    var campaignContent = evt.editor.getData();
    //console.log("data onchnage",newContent);
    this.setState({ description: campaignContent });
  };

  render() {
    const {
      title,
      date,
      description,
      callToActionURL,
      error,
      errormessage,
      status,
    } = this.state;
    //console.log("status", status);
    return (
      <section className="staticBdrContainer">
        <div className="loginFormContainer">
          <form onSubmit={this.onSubmit}>
            <div className="lgRow">
              <label htmlFor="title" className="labelTitle">
                Title
              </label>
              <input
                id="title"
                name="title"
                value={title}
                onChange={this.getInputValues}
                type="text"
                placeholder="Title"
                className="InputText"
                maxLength="100"
                required
              />
            </div>
            <div className="lgRow padTp1_5">
              <label htmlFor="description" className="labelTitle">
                Description
              </label>
              <CKEditor
                name="description"
                activeClass="p10 padTp0_5"
                content={this.state.description}
                events={{
                  afterPaste: this.afterPaste,
                  change: this.onChange,
                }}
              />
            </div>
            <div className="lgRow padTp1_5">
              <label htmlFor="status" className="labelTitle padBt0_5">
                Status
              </label>

              <select onChange={this.selectedStatus}>
                {this.renderStatus()}
              </select>
            </div>
            <div className="lgRow padTp1_5">
              <label htmlFor="imageUrl" className="labelTitle">
                Upload Image
              </label>

              <input
                name="imageUrl"
                onChange={this.onChangeHandler}
                type="file"
                placeholder="Description"
                className="input padTp0_5"
                id="imageUrl"
                accept="image/*"
              />
            </div>
            <div className="lgRow padTp1_5">
              <label htmlFor="callToActionURL" className="labelCallToActionURL">
                Call to action URL
              </label>
              <input
                id="callToActionURL"
                name="callToActionURL"
                value={callToActionURL}
                onChange={this.getInputValues}
                type="text"
                placeholder="Call to action URL"
                className="InputText"
                required
              />
            </div>
            <div className="lgRow padTp1_5">
              <label htmlFor="callToActionURL" className="labelCallToActionURL">
                Update Date
              </label>
              <input
                id="callToActionURL"
                name="callToActionURL"
                value={callToActionURL}
                onChange={this.getInputValues}
                type="text"
                placeholder="Call to action URL"
                className="InputText"
                required
              />
            </div>
            <div className="lgRow padTp1_5">
              <label htmlFor="date" className="labelTitle">
                Order Date
              </label>
              <input
                id="date"
                name="date"
                value={date}
                onChange={this.getInputValues}
                type="text"
                placeholder="Order Date"
                className="InputText"
                maxLength="100"
                required
              />
            </div>
            <div className="lgRow padTp0_5"></div>
            <div className="errorMessage">{this.state.errormessage}</div>
            <br />
            <div className="lgRow loginbutton">
              <button
                className="button button-primary button-login"
                type="submit"
              >
                {this.state.btnName}
              </button>
            </div>

            {error && <p>{error.message}</p>}
          </form>
        </div>

        <Loader isVisible={this.state.fetching} />
      </section>
    );
  }
}

export default withFirebase(CampaignAddEdit);
