import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import topapplogo from '../../assets/image/topapplogo.png';
//import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignInPage = () => (
  <main className="container flexbox loginFormWpr" style={{margin:"-60px"}}>
    <SignInForm />
    {/* <PasswordForgetLink /> */}
    {/* <SignUpLink /> */}
  </main>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: "",
  errorMsg: "",
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    if(email ==="" || password ==="")
    {
      this.setState({error: "Email and Password cannot be blank."});
    }else{
      this.setState({error: ""});
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        console.log("auth success");
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        console.log("auth error : "+error.message);
        this.setState({ error: error.message });
      });
    }
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error, errorMsg } = this.state;

   // const isInvalid = password === '' || email === '';
   
    return (
      

        <section className="staticBdrContainer loginFormCnt">
          <div className="loginFormContainer">
            <form onSubmit={this.onSubmit}>
              <div style={{ textAlign: "center", paddingBottom: "1.2rem" }}>
                <img src={topapplogo} alt="D-Link" title="D-Link" style={{ width: "150px", margin: "0 auto" }} />
              </div>
              <div className="lgRow">
                <label htmlFor="email" className="labelTitle">Email</label>
                <input
                  id="email"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  type="email"
                  placeholder="Email Address"
                  className="InputText"

                />
              </div>
              <div className="lgRow padTp1_5">
                <label htmlFor="password" className="labelTitle" >Password</label>

                <input
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  type="password"
                  placeholder="Password"
                  className="InputText"
                  id="password"
                />
              </div>
              <div className="errorMessage">
                
                {<p>{error}</p>}
              </div>
              <div className="lgRow loginbutton">
                <button className="button button-primary button-login"  type="submit">
                  Sign In
        </button>
              </div>


            </form>
          </div>
        </section>
     
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };
