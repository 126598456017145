export const LANDING ='/';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const USERS = '/users';
export const USERVIEW = '/userview';
export const LOCATIONREPORTS = '/locationreports';
export const LOCATIONREPORTVIEW = '/locationreportview';
export const CAMPAIGNS = '/campaigns';
export const CAMPAIGNADDEDIT = '/addcampaign';
export const LOGOUTURL=process.env.REACT_APP_LOGOUT_URL;
